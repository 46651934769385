import React, { useEffect } from "react";
import "../index.css"

function Header({ setChangeNavLink, changeNavLink }) {
  useEffect(() => {
    function changeNavColor() {
      if (window.scrollY >= 0) {
        setChangeNavLink("home");
      }
      if (window.scrollY >= 495) {
        setChangeNavLink("about");
      }
      if (window.scrollY >= 590) {
        setChangeNavLink("characters");
      }
      if (window.scrollY >= 1200) {
        setChangeNavLink("torture-watch");
      }
      if (window.scrollY >= 1720) {
        setChangeNavLink("gallery");
      }
    }

    window.addEventListener("scroll", changeNavColor);
    return () => window.addEventListener("scroll", changeNavColor);
  }, []);

  return (
    <div className="sticky-top">
      <nav className="navbar navbar-expand-lg  bg-light">
        <div className="container">
          <h1 className="m-0">
            <a href="/">
              <img
                src={require("../../assets/logo/mid_house_cafe_logo.png")}
                width={100}
                alt="mid_house_cafe_logo"
                style={{ marginRight: "10px" }}
              />
            </a>
          </h1>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "home"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="/"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "about"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="#about"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "characters"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="#characters"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Characters
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "torture-watch"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="#torture-watch"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Torture Watch
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "methods-of-torture"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="#methods-of-torture"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Methods of Torture
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "gallery"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="#gallery"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    changeNavLink === "previous-campaign"
                      ? "nav-link nav-active"
                      : "nav-link"
                  }
                  href="/"
                  spy="true"
                  smooth="true"
                  offset="50"
                  duration="500"
                  onClick={() => {
                    document
                      .getElementById("navbarText")
                      .classList.remove("show");
                  }}
                >
                  Previous Campaign
                </a>
              </li>
              ٖ
            </ul>
            <form className="d-flex" role="search">
              <button className="btn btn-color" type="submit">
                Get Involved
              </button>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
