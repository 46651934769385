import React from "react";
import "../index.css";

const Caresoul = () => {
  return (
    <div className="banner-crsl-main-div" data-aos="zoom-in">
      {/* <video autoPlay muted loop>
      <source src={require("../../assets/bannervideo/video1.mp4")} type="video/mp4"/>
     </video> */}
      <a href="https://www.youtube.com/watch?v=KCqZdNCQIMs" target="_blank">
        <img
          src={require("../../assets/carousel/carousel_1.webp")}
          alt="carousel_1"
          width={"100%"}
          height={"100%"}
        />
      </a>
    </div>
  );
};

export default Caresoul;

{
  /* <div id="carouselExampleIndicators" className="carousel slide banner-crsl-main-div" data-bs-ride="carousel">
<div className="carousel-indicators">
<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
</div>
<div className="carousel-inner">
<div className="carousel-item active">
<img src="..." className="d-block w-100" alt="..."/>
</div>
<div className="carousel-item">
<img src="..." className="d-block w-100" alt="..."/>
</div>
<div className="carousel-item">
<img src="..." className="d-block w-100" alt="..."/>
</div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div> */
}
