import React from "react";
import "../index.css"

const About = () => {
  return (
    <div id="about">
      <div className="container py-2">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100 rounded-0 border-0">
              <div className="card-body">
                <h3>
                  <button
                    className="btn btn-color w-100"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample1"
                    aria-expanded="false"
                    aria-controls="collapseExample1"
                  >
                    About the Cafe
                  </button>
                </h3>
                <div className="collapse" id="collapseExample1">
                  <div className="card card-body">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        //   lineHeight: '33px',

                        color: "black",
                      }}
                    >
                      Welcome to Mid House Cafe! The Mid House Cafe is a cosy
                      place for all parliamentarians, no matter their political
                      leanings, to sit and debate legislative matters together,
                      share memes and discuss the need for an anti-torture bill.
                      The story follows the discussion between a group of
                      politicians during their tea break, just before the vote
                      on an anti-torture bill, peppered by live media coverage
                      and analysis of the developments. It leads the audience
                      through various layers of discourse by weaving through a
                      talk show, an informal huddle in a café and a world online
                      affecting everything. The characters are fictional and an
                      amalgamation of figures from Pakistan’s political spectrum
                      as well as news media landscape. The production adds to
                      the conversation about the use of torture and forced
                      confessions in Pakistan, flaws in the criminal justice
                      system and the resultant gross human rights violations.
                      Will a spoonful of humour help get the bill passed? Watch
                      online on June 26th at Justice Project Pakistan's {""}
                      <a
                        href="https://www.facebook.com/JusticeProjectPakistan"
                        className="text-primary"
                      >
                        Facebook.
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 rounded-0 border-0">
              <div className="card-body">
                <h3>
                  <button
                    className="btn btn-color w-100"
                    type="button2"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample2"
                    aria-expanded="false"
                    aria-controls="collapseExample2"
                  >
                    About the Campaign
                  </button>
                </h3>
                <div className="collapse" id="collapseExample2">
                  <div className="card card-body">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        //   lineHeight: '33px',

                        color: "black",
                      }}
                    >
                      Justice Project Pakistan (JPP) presents
                      #CriminalizeTorture – a national public engagement
                      campaign aiming to raise awareness about the pervasive use
                      of torture by the police and increase support for
                      legislation criminalising torture in Pakistan. It includes
                      outreach initiatives such as bringing vulnerable
                      communities susceptible to torture to the forefront,
                      partnering with educational institutes, engaging the youth
                      as well as garnering public support to stress the
                      importance of criminalising torture. During the course of
                      the campaign, JPP has collaborated with Blackstone School
                      of Law and Lahore University of Management Sciences to
                      host on-campus activities, participated in Aurat March
                      Lahore and Multan, co-produced the play Teesri Dhun in
                      Lahore, and co-organised a side event at the United
                      Nations Human Rights Council’s 49th regular session with
                      the World Organisation Against Torture.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 rounded-0 border-0">
              <div className="card-body">
                <h3>
                  <button
                    className="btn btn-color w-100"
                    type="button2"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample3"
                    aria-expanded="false"
                    aria-controls="collapseExample3"
                  >
                    About 26th June
                  </button>
                </h3>
                <div className="collapse" id="collapseExample3">
                  <div className="card card-body">
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "20px",
                        //   lineHeight: '33px',

                        color: "black",
                      }}
                    >
                      The United Nations’ International Day in Support of
                      Victims of Torture is annually observed on June 26th to
                      remind people that torture is not only unacceptable – it
                      is also a crime. The Convention against Torture and Other
                      Cruel, Inhuman or Degrading Treatment or Punishment
                      <a href="https://www.ohchr.org/en/instruments-mechanisms/instruments/convention-against-torture-and-other-cruel-inhuman-or-degrading">
                        (UNCAT)
                      </a>
                      is an international human rights treaty, under the purview
                      of the United Nations, that aims to prevent torture and
                      other acts of cruel, inhuman, degrading treatment or
                      punishment around the world. Pakistan ratified UNCAT in
                      2010 and the government is obligated to pass legislation
                      to effectively define and criminalise torture.
                      Furthermore, in order to maintain preferential trade
                      status under the European Union GSP+ scheme, Islamabad
                      must fulfil its international law obligations under the
                      core human rights treaties, including
                      <a href="https://www.ohchr.org/en/instruments-mechanisms/instruments/convention-against-torture-and-other-cruel-inhuman-or-degrading">
                        UNCAT.
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
