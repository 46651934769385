export const personData = [
  {
    id: 1,
    iamge: "/images/character/Sajjad_Raza.jpg",
    title: "Sajjad Raza",
    text: "Founding member of the Waghaira Waghaira Party and one of the very few people alive who understands the difference between a bill and an act.",
  },

  {
    id: 2,
    iamge: "/images/character/Makhdoom.jpg",
    title: "Makhdoom Sahab",
    text: "Honorary Chairperson of the Pakistan Sabz Bagh Party and an avid sherwani enthusiast, Makhdoom Sahab is known for switching political affiliations every five years to join the next government.",
  },

  {
    id: 3,
    iamge: "/images/character/Sana_Sheikh.jpg",
    title: "Sana Sheikh",
    text: "Sana Sheikh has the exclusive rights to represent her party, the National Ghul Ghapara Alliance, on human rights issues as she doesn’t have to win any general elections. ",
  },

  {
    id: 4,
    iamge: "/images/character/img_avatar.png",
    title: "Baloch Sahab",
    text: "He has the power to remain absent throughout his life and since most of us can’t remember or pronounce his name correctly, we tend to refer to him as Baloch Sahab.",
  },

  {
    id: 5,
    iamge: "/images/character/Maulana_Hamdaan.jpg",
    title: "Maulana Hamdan",
    text: "Maulana Hamdan has a fake beard and real sentiments. He and his party YOLO-P have been in three governments, six opposition movements and eight cabinets.",
  },
  {
    id: 6,
    iamge: "/images/character/Malik_Arshad.jpg",
    title: "Malik Arshad",
    text: "Senior politician and part-time singer Malik Arshad is the spokesperson of the Pakistan Sacha Pyar Movement and knows the art of winning elections and avoiding paying bills.",
  },
  {
    id: 7,
    iamge: "/images/character/Shola_Nisar.jpg",
    title: "Shola Nisar",
    text: "Senior analyst and columnist Shola Nisar is a retired revolutionary with no tolerance for disagreement. He is always ready to pick a bone with his fellow panelists.",
  },

  {
    id: 8,
    iamge: "/images/character/Jaeza_Umer.png",
    title: "Jaeza Umer",
    text: "Jaeza Umer belongs to the new generation of analysts who prefers to be right than effective.",
  },

  {
    id: 8,
    iamge: "/images/character/Mezban_Bukhari.jpg",
    title: "Mezban Bechari",
    text: "She looks familiar because she appears on your screen every day. Her show is 60% drama, 35% sansani and 5% news.",
  },

  {
    id: 10,
    iamge: "/images/character/Gulukaar_Ahmed.jpg",
    title: "Gulukaar Ahmed",
    text: "Retired singer and part-time politician Gulukaar Ahmed lives in a time when his songs were more popular than his political slogans.",
  },

  {
    id: 11,
    iamge: "/images/character/Muqarar_Bhatti.jpg",
    title: "Muqarar Bhatti",
    text: "Veteran reporter and self-proclaimed analyst Muqarar Bhatti knows the ins and outs of every thing that you had no idea even existed.",
  },

  {
    id: 12,
    iamge: "/images/character/Waiter.jpg",
    title: "Waiter Bilu",
    text: "Waiter Bilu holds the power to break the fourth wall and in that process some pots and pans.",
  },

  {
    id: 13,
    iamge: "/images/character/Manager_Chaudhary.jpg",
    title: "Manager Chaudhary",
    text: "Every politician has an open account at his cafe and every parliamentarian owes him a favour.",
  },

  {
    id: 14,
    iamge: "/images/character/Neeli_Rana.jpg",
    title: "Neeli Rana",
    text: "Pakistani Khwaja Sira community’s veteran activist, Nneeli Rana stands on the frontlines in the fight for equal treatment of all groups of society.",
  },

  {
    id: 15,
    iamge: "/images/character/quiad_1.PNG",
    title: "Quaid-e-Azam",
    text: "I have no idea why my picture is hanging in this cafe?",
  },
  {
    id: 16,
    iamge: "/images/character/iqbal.jpg",
    title: "Allama Iqbal",
    text: "This is hilarious, the best show ever. When is the next episode?",
  },
];
