import React from "react";
import "../index.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { personData } from "../../data/personData";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  loop: true,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },

    1000: {
      items: 3,
    },
  },
};

function MyCard({ data }) {
  return (
    <div data-aos="fade-up">
      {/* <div
        className="card h-100 align-items-center border-0 text-white"
        style={{ backgroundColor: "#045050", marginTop: "10px" }}
      > */}

      {/* <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front"></div>
          <img
            style={{ width: "200px", height: "200px", borderRadius: "100%" }}
            src={data.iamge}
            className="card-img-top"
            alt="..."
          />
          <div className="text-white flip-card-back">
            <h5 className="card-title text-center">{data.title}</h5>
            <p className="card-text text-center">{data.text}</p>
           
          </div>
        </div>
      </div> */}

      {/* </div> */}

      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src={data.iamge}
              alt="..."
              style={{ width: "360px", height: "360px" }}
            />
            <h5
              className="p-2"
              style={{ backgroundColor: "#045050", color: "#FFFFFF" }}
            >
              {data?.title}
            </h5>
          </div>

          <div className="flip-card-back">
            <p className="m-3">{data?.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Characters = () => {
  return (
    <div id="characters">
      <div style={{ backgroundColor: "lightgrey" }} className="py-5">
        <div className="container mb-4">
          <h3>
            <b> CHARACTERS </b>
          </h3>
          <p>
            The characters are fictional and an amalgamation of figures from
            Pakistan’s political spectrum as well as news media landscape.
          </p>
        </div>

        <div className="container">
          <OwlCarousel {...options}>
            {personData.map((data) => (
              // <Link
              //   style={{ textDecoration: "none" }}
              //   key={personData.id}
              //   to={`/mnadetail/${personData.id}`}
              // >
              <MyCard data={data} />
              // </Link>s
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Characters;
