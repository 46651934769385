import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Footer from "./components/Footer/Footer";
import Header from "./components/navbars/Header";

function App() {
  const [changeNavLink, setChangeNavLink] = useState();
  return (
    <BrowserRouter>
      {/* <UpperNavbar /> */}
      <Header
        setChangeNavLink={setChangeNavLink}
        changeNavLink={changeNavLink}
      />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
