import React from "react";
import "../index.css"
import { FaTwitter, FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { HiMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="footer-main-div">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-3 g-3 py-5 align-items-center">
          <div className="col">
            <div className="row row-cols-4 row-cols-md-4 row-cols-lg-4 align-items-center">
              <div className="col">
                <a href="https://jpp.org.pk/">
                  <img
                    src={require("../../assets/logo/JPP_Logo.png")}
                    alt="JPP_Logo"
                    width={"70px"}
                  />
                </a>
              </div>
              <div className="col">
                <a href="https://optiwisesolutions.com/">
                  <img
                    src={require("../../assets/logo/Optiwise_Logo.png")}
                    alt="Optiwise_Logo"
                    width={"70px"}
                  />
                </a>
              </div>
              <div className="col">
                <a href="https://www.facebook.com/mishermayl/">
                  <img
                    src={require("../../assets/logo/Mishermayl_Productions.png")}
                    alt="Mishermayl_Productions"
                    width={"70px"}
                  />
                </a>
              </div>
              <div className="col">
                <a href="https://www.instagram.com/cocoonartsand/">
                  <img
                    src={require("../../assets/logo/Cocoon_Arts.jpeg")}
                    alt="Cocoon_Arts"
                    width={"70px"}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col d-flex justify-content-center">
            <div className="footer-section">
              <div className="footer-icons">
                <li>
                  <a
                    href="https://twitter.com/JusticeProject_"
                    className="linkhov"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/JusticeProjectPakistan"
                    className="linkhov"
                  >
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/justiceprojectpak/"
                    className="linkhov"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
                <li>
                  <a href="mailto:info@jpp.org.pk" className="linkhov">
                    <HiMail />
                  </a>
                </li>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="footer-section">
              <div className="row row-cols-1 row-cols-md-2">
                <div className="col m-0">
                  <button className="btn btn-color w-100 my-2">
                    <a
                      href="https://www.change.org/p/the-pakistani-government-criminalise-torture-in-pakistan?recruiter=1212779678&utm_source=share_petition&utm_medium=copylink&utm_campaign=share_petition"
                      target="_blank"
                    >
                      Sign the Petition
                    </a>
                  </button>
                </div>
                <div className="col">
                  <button className="btn btn-color w-100 my-2">
                    <a
                      href="http://twitter.com/intent/tweet?text=%23CriminalizeTourture%20%0A%23QanoonBanao"
                      target="_blank"
                    >
                      Tweet About It
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
