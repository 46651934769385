import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../index.css";

import YouTube from "react-youtube";

const opts = {
  height: "206",
  width: "366",
  playerVars: {
    mute: true,
  },
};

const options = {
  margin: 8,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  loop: true,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },

    1000: {
      items: 3,
    },
  },
};

const Gallery = () => {
  return (
    <div id="gallery">
      <div className="gallery">
        <div className="container py-5">
          <h2 className="mb-4">
            <b>GALLERY</b>
          </h2>
          <OwlCarousel {...options}>
            <div>
              <YouTube
                videoId="Gr3l4WTXGDc"
                opts={opts}
                className="youtube-video"
              />
            </div>

            <div>
              <YouTube
                videoId="OZLqoU9a_hI"
                opts={opts}
                className="youtube-video"
              />
            </div>

            <div>
              <YouTube
                videoId="KT1DzQ5tRD8"
                opts={opts}
                className="youtube-video"
              />
            </div>

            <div>
              <YouTube
                videoId="ZeAu4AjOoN4"
                opts={opts}
                className="youtube-video"
              />
            </div>

            <div>
              <YouTube
                videoId="KCqZdNCQIMs"
                opts={opts}
                className="youtube-video"
              />
            </div>

            <div>
              <img
                src={require("../../assets/gallery/gallery_img_1.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_1"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_2.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_2"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_3.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_3"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_4.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_4"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_5.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_5"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_6.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_6"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_7.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_7"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_8.JPG")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_8"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_9.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_9"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_10.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_10"
              />
            </div>
            <div>
              <img
                src={require("../../assets/gallery/gallery_img_11.jpg")}
                width={"100%"}
                height={"100%"}
                alt="gallery_img_11"
              />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
