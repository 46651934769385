import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../index.css";

const options = {
  margin: 8,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  loop: true,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
    },

    600: {
      items: 2,
    },

    1000: {
      items: 3,
    },
  },
};
function TortureWatch() {
  return (
    <div id="torture-watch">
      <div className="gallery" data-aos="fade-up">
        <div className="container py-5">
          <h2 className="mb-4">
            <b>TORTURE WATCH</b>
          </h2>
          <OwlCarousel {...options}>
            <div>
              <img
                src={require("../../assets/tortureWatch/1.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_1"
              />
            </div>

            <div>
              <img
                src={require("../../assets/tortureWatch/2.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_2"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/3.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_3"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/4.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_4"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/5.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_5"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/6.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_6"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/7.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_7"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/8.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_8"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/9.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_9"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/10.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_10"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/11.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_11"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/12.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_12"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/13.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_13"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/14.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_14"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/15.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_15"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/16.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_16"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/17.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_17"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/18.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_18"
              />
            </div>
            <div>
              <img
                src={require("../../assets/tortureWatch/19.jpg")}
                width={"100%"}
                height={"100%"}
                alt="torture_watch_19"
              />
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

export default TortureWatch;
