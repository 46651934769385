import React from "react";

import Caresoul from "../../components/Home/Caresoul";
import PersoninfoSlider from "../../components/Home/Characters";
import Gallery from "../../components/Home/Gallery";
import TortureWatch from "../../components/Home/TortureWatch";
import About from "../../components/Home/About";

const Home = () => {
  return (
    <div>
      <Caresoul />
      <About />
      <PersoninfoSlider />
      <TortureWatch />
      <Gallery />
    </div>
  );
};

export default Home;
